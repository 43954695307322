import { Navigate } from 'react-router-dom';

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === '') {
      errorState[item] = 'This field is required';
    }
  });
  return errorState;
};
export const formatCreatedAtDateTime = (timestamp) => {
  const date = new Date(timestamp);
  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };
  const timeOptions = { hour: '2-digit', minute: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
  return `${formattedDate} ${formattedTime}`;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== ' '
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to set deadline for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + '-' + d + '-' + y) : (date = y + '-' + d + '-' + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse) => {
  var dateformat = date.split('-');
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + '-' + dateformat[0] + '-' + dateformat[1])
    : (date = dateformat[1] + '-' + dateformat[2] + '-' + dateformat[0]);
  return date;
};

//Month Names
export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

//todays Date
export const todaysDate = new Date();

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + ' ' + d + ', ' + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = '0' + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = '0' + m);
  let y = rdate.getFullYear();
  rdate = y + '-' + m + '-' + d;

  return rdate;
};

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

//shortens a long string
export const truncate = (str, n) => {
  return str.length > n
    ? str.substr(0, n - 1) + ' ' + truncate(str.substr(n - 1, str.length), n)
    : str;
};

export const RedirectAs404 = ({ location }) => (
  <Navigate to={Object.assign({}, location, { state: { is404: true } })} />
);

// returns upload url
export const getUploadParams = () => {
  return { url: 'https://httpbin.org/post' };
};

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const bulkActionOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'InActive', label: 'InActive' }
];
export const actionS3Objects = ['getObject', 'putObject', 'deleteObject']; // S3 Bucket Actions

export const formatValue = (n) => {
  return Number(n)
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const ItemListMappings = {
  title: 'Title',
  subtitle1: 'Subtitle 1',
  subtitle2: 'Subtitle 2',
  subtitle3: 'Subtitle 3',
  subtitle4: 'Subtitle 4',
  subtitle5: 'Subtitle 5',
  title_image: 'Title Image'
};

export function validateImage(file, customValidator) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      resolve();
      // if (customValidator(img)) {
      //   resolve();
      // } else {
      //   reject("Please upload a square size image ");
      // }
    };

    img.onerror = () => {
      reject('Invalid image format or unable to load the image.');
    };
  });
}
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const auctionStatus = {
  UPCOMING: 2, //'UPCOMING',
  NON_SCHEDULED: 3, //'NON_SCHEDULED',
  LIVE: 1, //'LIVE',
  COMPLETED: 4, //'COMPLETED',
  CANCELLED: 5, //'CANCELLED'
  REPOSTED: 6 //'REPOSTED'
};
export const stringAuctionStatus = {
  2: 'UPCOMING', //'UPCOMING',
  3: 'NON_SCHEDULED', //'NON_SCHEDULED',
  1: 'LIVE', //'LIVE',
  4: 'COMPLETED', //'COMPLETED',
  5: 'CANCELLED', //'CANCELLED'
  6: 'REPOSTED' //'REPOSTED'
};
export const carStatus = {
  ADDED: 'ADDED',
  READY_FOR_AUCTION: 'READY_FOR_AUCTION',
  AUCTION_SCHEDULED: 'AUCTION_SCHEDULED',
  AUCTION_LIVE: 'AUCTION_LIVE',
  AUCTION_COMPLETED: 'AUCTION_COMPLETED',
  AUCTION_CANCELLED: 'AUCTION_CANCELLED',
  CONCIERGE_BOOKED: 'CONCIERGE_BOOKED',
  ITEM_TRANSFERED: 'ITEM_TRANSFERED'
};
export const validateEmail = (value = '') => {
  const isValidEmail = value?.match(emailRegex);

  if (isValidEmail) {
    return true;
  }
  // toast.error('Enter valid Email');
  return false;
};
export const validatePassword = (newPassword, confirmPassword) => {
  const errors = [];

  if (!newPassword) {
    errors.push('Enter Password');
  }

  if (newPassword !== confirmPassword) {
    errors.push("Passwords don't match");
  }

  if (!/(?=.*[A-Z])/.test(newPassword)) {
    errors.push('Password should contain at least 1 uppercase character');
  }

  if (!/(?=.*[a-z])/.test(newPassword)) {
    errors.push('Password should contain at least 1 lowercase character');
  }

  if (!/(?=.*\d)/.test(newPassword)) {
    errors.push('Password should contain at least 1 digit');
  }

  if (!/(?=.*[!@#$%^&*()_\-+={}[\]:;"'<>,.?\/])/.test(newPassword)) {
    errors.push('Password should contain at least 1 special character');
  }

  if (/\s/.test(newPassword)) {
    errors.push('Password should not contain any whitespaces');
  }

  if (!/^.{8,}$/.test(newPassword)) {
    errors.push('Password should be at least 8 characters long');
  }

  if (errors.length > 0) {
    // toast.error(errors.join(", "));
    return false;
  } else {
    return true;
  }
};
export const UserVerificationStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  BLOCKED: 'blocked',
  PENDING_DOCUMENTS: 'pending_documents'
};
export const USER_TYPES = {
  User: 1,
  Admin: 2
};
export const UserRegisterTypes = {
  Personal: 'Personal',
  Dealer: 'Dealer'
};
export const DealerTypes = {
  Freelancer: 'Freelancer',
  Company: 'Company'
};
export const Roles = {
  SuperAdmin: 'SuperAdmin',
  Admin: 'Admin',
  User: 'User',
  RM: 'RM',
  Concierge: 'Concierge',
  CustomerSupport: 'CustomerSupport'
};
export const Emirates = [
  'Abu Dhabi',
  'Dubai',
  'Sharjah',
  'Ajman',
  'Umm Al-Quwain',
  'Ras Al Khaimah',
  'Fujairah'
];

export const TransportTypes = ['Included', 'Excluded'];

export const LeadTypes = {
  Website: 'Website',
  App: 'App'
};

export const flagStatus = {
  OPEN: 'OPEN',
  IN_REVIEW: 'IN_REVIEW',
  RESOLVED: 'RESOLVED',
  DISMISSED: 'DISMISSED'
};

export const flagType = {
  CRITICAL: 'CRITICAL',
  WARNING: 'WARNING',
  INFORMATION: 'INFORMATION'
};
