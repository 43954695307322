import React, { useEffect, useState } from 'react';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import {
  Block,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard
} from '../../components/Component';
import { RSelect } from '../../components/Component';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Badge,
  Spinner,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row
} from 'reactstrap';
import ConfirmModal from '../../pages/verticals/components/ConfirmModal';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { sendNotifications } from '../../redux/features/usersSlice/notificationSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PushNotificationData() {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [type, setType] = useState('');
  const [showModal, setShowModal] = useState(false);
  

  const closeModal = () => {
    setShowModal(false);
    setTitle(null);
    setContent(null);
  };
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const handleSubmit = () => {
    if(!type){
      toast.error('Please select to which users you want to send notifications');
      return;
    }
    if(type&&type.value==='Select'){
      toast.error('Please select to which users you want to send notifications');
      return;
    }
    setShowModal(true);
  };



  const dispatch = useDispatch();

  const Type = ['Select','ALL', 'PRO_USERS'];

  const { errors, register } = useForm();

  const broadcast = (e) => {
    e.preventDefault();
    // console.log(title, content, type);

    let data={
      title,
      content,
      type:type.value
    }
    // console.log(data)
    dispatch(sendNotifications(data));

  };

  return (
    <React.Fragment>
      <Head title="App Broadcast"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>App Broadcast</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <form onSubmit={(e) =>
            {
              e.preventDefault();
              handleSubmit();
             }
            }
               >
              <Col sm="6">
                <div className="form-group mb-2">
                  <label className="form-label" htmlFor="title">
                    Title*
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="title"
                      className="form-control"
                      name="title"
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group mb-2">
                  <label className="form-label" htmlFor="content">
                    Content*
                  </label>
                  <div className="form-control-wrap">
                    <textarea
                      ref={register({
                        required: true
                      })}
                      type="textarea"
                      className="form-control form-control-sm"
                      id="fv-message"
                      name="message"
                      placeholder="Write your message"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                    />
                    {errors.message && (
                      <span className="invalid">This field is required</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group mb-2">
                  <label className="form-label" htmlFor="first-name">
                    To*
                  </label>
                  <div className="form-control-wrap">
                    <RSelect
                      isMulti={false}
                      value={type}
                      onChange={(e) => setType(e)}
                      options={Type.map((e) => ({ label: e, value: e }))}
                    />
                  </div>
                </div>
              </Col>

              <Col sm="6">
                <Button type="submit" className="bg-primary text-white mt-2">
                  {loading ? (
                    <>
                      <Spinner size="sm" color="light" />
                      Please Wait
                    </>
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Col>
            </form>
          </div>
        </Block>
      </Content>
      {/* ****** Modal Start ****** */}
      {showModal ? (
        <ConfirmModal
          msg={'Send push notifications'}
          name={''}
          open={showModal}
          loading={loading}
          handleClick={handleModal}
          handleConfirm={broadcast}
          handlecloseModal={closeModal}
        />
      ) : null}
      {/* ****** Modal End ****** */}
    </React.Fragment>
  );
}
