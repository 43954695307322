import Axios from "axios";
import { axios } from "./axios";
import { toast } from "react-toastify";
import { actionS3Objects } from "./Utils";

export const uploadToS3 = async (file, fileName, contentType = "image/*") => {
  try {
    const uploadData = JSON.stringify({
      action: `putObject`,
      fileName,
    });
    const resp = await axios.post(`/admin/upload`, uploadData);
    const s3url = resp.data.data.signedRequest;

    if (s3url) {
      const myHeaders = new Headers({
        "Content-Type": contentType,
      });
      await Axios.put(s3url, file, { myHeaders });
      return s3url;
    }
  } catch (err) {
    // console.log(err);
    toast.error("Server error. Try Again!");
    return null;
  }
};
export const uploadManyToS3 = async (data = [{ file: null, type:"image/*", fileName: "" }]) => {
  try {
    const requests = data?.map((item) => {
      const uploadData = JSON.stringify({
        action: `putObject`,
        fileName: item?.fileName
      });
      const req = axios.post(`/admin/upload`, uploadData);
      return req;
    });
    const responses = await Promise.all(requests);

    const s3Urls = responses.map((item) => item.data?.data?.signedRequest);

    const s3Requests = data.map((item, i) => {
      const myHeaders = new Headers({
        'Content-Type': item?.type
      });

      const req = Axios.put(s3Urls[i], item?.file, { myHeaders });

      return req;
    });

    const s3Responses = await Promise.all(s3Requests);
    return true;
  } catch (err) {
    // console.log(err);
    return false;
  }
};
export const getImage = async (img) => {
  try {
    if (!img) {
      throw new Error("Provide valid image path");
    }
    const { data } = await axios.post(
      "/device/api/v1/upload",
      JSON.stringify({
        action: `${actionS3Objects[0]}`,
        // fileName: `${img}`,
        fileName: typeof img === "string" ? img : img?.thumbnail,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data?.data?.signedRequest;
  } catch (err) {
    return null;
  }
};
export const getPdf = async (img) => {
  try {
    if (!img) {
      throw new Error("Provide valid image path");
    }
    const { data } = await axios.post(
      "/device/api/v1/upload",
      JSON.stringify({
        action: `${actionS3Objects[0]}`,
        fileName: typeof img === "string" ? img : img?.thumbnail,
        "ResponseContentType": "application/pdf"
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data?.data?.signedRequest;
  } catch (err) {
    return null;
  }
};

