import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadToS3 } from "../../../utils/uploadImageToS3";
import uuid from "react-uuid";

const initialState = {
  data: {},
  updateStatus: "init",
  loading: false,
  error: null,
  updateError: null,
  createStatus: 'init',
  createError: null,
  createLoading: false,
};
const fetchUserDetails = createAsyncThunk("userDetails", (id) => {
  let url = `/admin/user/${id}`;

  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});
export const clearUserDetails = createAsyncThunk('clearUserDetails', async () => {
  return null; // You can return any value here as it won't be used.
});
const updateUserDetails = createAsyncThunk('updateUserDetails', async (data) => {
  const { id, body } = data;
  let url = `/admin/user/partial-update/${id}`;

  try {
    const response = await axios.put(url, body);
    toast.success('User details updated');
    window.location.reload();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message); // Throw an error with the message
  }
});
const verifyUserDocuments = createAsyncThunk('verifyUserDocuments', async (data) => {
  let url = `/admin/user/verify-user/`;
  try {
    const response = await axios.post(url, data);
    toast.success('User Verified');
    window.location.reload();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message); // Throw an error with the message
  }
});
const rejectUserDocuments = createAsyncThunk('rejectUserDocuments', async (data) => {
  let url = `/admin/user/reject-user/`;
  try {
    const response = await axios.post(url, data);
    toast.success('Rejected');
    window.location.reload();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message); // Throw an error with the message
  }
});
const blockUser = createAsyncThunk('blockUser', async (data) => {
  let url = `/admin/user/block-user/`;
  try {
    const response = await axios.post(url, data);
    toast.success('Blocked');
    window.location.reload();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message); // Throw an error with the message
  }
});
const createUser = createAsyncThunk('createUser', async (data) => {
  try {
    const tradeLicense = data['trade_license'][0]
    if (tradeLicense) {
      const fileType = tradeLicense.name.split('.').pop();
      const filePath = `dealers/trade_licenses/${uuid()}.${fileType}`;
      await uploadToS3(tradeLicense, filePath, tradeLicense.type)
      delete data.trade_license;
      data["documents.trade_license.document"] = filePath
    }
    const logo = data['company_logo'][0]
    if (logo) {
      const fileType = logo.name.split('.').pop();
      const filePath = `dealers/logo/${uuid()}.${fileType}`;
      await uploadToS3(logo, filePath, logo.type)
      data["company_logo"] = filePath
    }
    const url = `/admin/auth/register`;
    const response = await axios.post(url, data);
    toast.success('User Added');
    window.location.reload();
    return response.data;

  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});
const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    resetUpdateStatus: (state) => {
      state.updateStatus = "init";
      state.updateError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = action.error.message;
    });
    builder.addCase(updateUserDetails.pending, (state) => {
      state.updateStatus = "loading";
      state.updateError = null;
    });
    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.updateStatus = "success";
      state.updateError = null;
      state.data = action.payload.data;
    });
    builder.addCase(updateUserDetails.rejected, (state, action) => {
      state.updateStatus = "error";
      state.updateError = action.error.message;
    });
    builder.addCase(createUser.pending, (state) => {
      state.updateStatus = "loading";
      state.updateError = null;
      state.createStatus = "loading";
      state.createError = null;
      state.createLoading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.updateStatus = "success";
      state.updateError = null;
      state.data = action.payload.data;
      state.createStatus = "success";
      state.createError = null;
      state.data = action.payload.data;
      state.createLoading = false;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.updateStatus = "error";
      state.updateError = action.error.message;
      state.createStatus = "error";
      state.createError = action.error.message;
      state.createLoading = false;
    });
    builder.addCase(verifyUserDocuments.pending, (state) => {
      state.updateStatus = "loading";
      state.updateError = null;
    });
    builder.addCase(verifyUserDocuments.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.updateStatus = "success";
      state.updateError = null;
      state.data = action.payload.data;
    });
    builder.addCase(verifyUserDocuments.rejected, (state, action) => {
      state.updateStatus = "error";
      state.updateError = action.error.message;
      console.log(state.updateError)
    });
    builder.addCase(rejectUserDocuments.pending, (state) => {
      state.updateStatus = "loading";
      state.updateError = null;
    });
    builder.addCase(rejectUserDocuments.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.updateStatus = "success";
      state.updateError = null;
      state.data = action.payload.data;
    });
    builder.addCase(rejectUserDocuments.rejected, (state, action) => {
      state.updateStatus = "error";
      state.updateError = action.error.message;
      console.log(state.updateError)
    });
    builder.addCase(blockUser.pending, (state) => {
      state.updateStatus = "loading";
      state.updateError = null;
    });
    builder.addCase(blockUser.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.updateStatus = "success";
      state.updateError = null;
      state.data = action.payload.data;
    });
    builder.addCase(blockUser.rejected, (state, action) => {
      state.updateStatus = "error";
      state.updateError = action.error.message;
      console.log(state.updateError)
    });
    builder.addCase(clearUserDetails.fulfilled, (state) => {
      // Handle clearUserDetails fulfilled action
      state.data = {}; // Clear user details
    });
  },
});

export default userDetailsSlice.reducer;
export const { resetUpdateStatus } = userDetailsSlice.actions;
export { fetchUserDetails, updateUserDetails, createUser, verifyUserDocuments, rejectUserDocuments, blockUser };
