import { startCase } from 'lodash';
import { auctionStatus, carStatus, flagStatus } from './Utils';

export const getColor = (status) => {
  switch (status) {
    case carStatus.AUCTION_LIVE:
      return 'danger';
    case carStatus.READY_FOR_AUCTION:
      return 'secondary';
    case carStatus.AUCTION_SCHEDULED:
      return 'secondary';
    case carStatus.AUCTION_CANCELLED:
      return 'danger';
    case carStatus.ITEM_TRANSFERED:
    case carStatus.AUCTION_COMPLETED:
      return 'success';
    case carStatus.CONCIERGE_BOOKED:
      return 'secondary';
    case carStatus.ADDED:
      return 'info';
    default:
      return 'gray';
  }
};

export const getAuctionStatusDotColor = (status) => {
  switch (startCase(status)) {
    case auctionStatus.PENDING:
      return 'warning';
    case auctionStatus.COMPLETED:
      return 'secondary';
    case auctionStatus.UPCOMING:
      return 'secondary';
    case 'Unsold':
      return 'danger';
    case 'Sold':
    case 'Bought':
      return 'success';
    case auctionStatus.NON_SCHEDULED:
      return 'success';
    case auctionStatus.LIVE:
      return 'danger';
    default:
      return 'gray';
  }
};
export const getAuctionStatusBgColor = (status) => {
  switch (startCase(status)) {
    case auctionStatus.PENDING:
      return 'warning';
    case auctionStatus.COMPLETED:
      return 'success';
    case auctionStatus.UPCOMING:
      return 'secondary';
    case 'Unsold':
      return 'danger';
    case 'Sold':
    case 'Bought':
      return 'success';
    case auctionStatus.NON_SCHEDULED:
      return 'success';
    case auctionStatus.LIVE:
      return 'danger';
    default:
      return 'gray';
  }
};
export const getAuctionStatus = (status) => {
  switch (status) {
    case auctionStatus.UPCOMING:
      return 'UPCOMING';
    case auctionStatus.COMPLETED:
      return 'COMPLETED';
    case auctionStatus.NON_SCHEDULED:
      return 'NON_SCHEDULED';
    case auctionStatus.CANCELLED:
      return 'CANCELLED';
    case auctionStatus.REPOSTED:
      return 'REPOSTED';
    case auctionStatus.LIVE:
      return 'LIVE';
    default:
      return '';
  }
};

export const getFlagStatus = (status) => {
  switch (status) {
    case flagStatus.OPEN:
      return 'OPEN';
    case flagStatus.IN_REVIEW:
      return 'IN_REVIEW';
    case flagStatus.RESOLVED:
      return 'RESOLVED';
    case flagStatus.DISMISSED:
      return 'DISMISSED';

    default:
      return '';
  }
};

export const getFlagStatusColor = (status) => {
  switch (status) {
    case flagStatus.OPEN:
      return 'secondary';
    case flagStatus.IN_REVIEW:
      return 'info';
    case flagStatus.DISMISSED:
      return 'danger';
    case flagStatus.RESOLVED:
      return 'success';
    default:
      return 'gray';
  }
};
