import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import axios from "axios";
import { apiUrl } from "./utils/configEnv";
import { ThemeProvider, createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import { ToastContainer } from "react-toastify";

const Error404Modern = lazy(() => import("./pages/error/404-modern"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#800020",
    },
    secondary: {
      main: "#800020",
    },
    error: {
      main: red.A400,
    },
  },
});
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Suspense fallback={<div />}>
          <App />
          <ToastContainer />
        </Suspense>
      </React.Fragment>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
