import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage/session';
import authSlice from './features/authSlice/authSlice';
import usersSlice from './features/usersSlice/usersSlice';
import auctionSlice from './features/auctionSlice/auctionSlice';
import itemsSlice from './features/itemsSlice/itemsSlice';
import liveAuctionsSlice from './features/auctionSlice/liveAuctionsSlice';
import topBiddedAuctionsSlice from './features/auctionSlice/topBiddedAuctionsSlice';
import thunk from 'redux-thunk';
import dashboardSlice from './features/dashboardSlice/dashboardSlice';
import auctionDetailsSlice from './features/auctionSlice/auctionDetailsSlice';
import carDetailsSlice from './features/itemsSlice/itemDetailsSlice';
import servicesSlice from './features/servicesSlice/servicesSlice';
import vouchersSlice from './features/vouchersSlice/vouchersSlice';
import serviceProvidersSlice from './features/serviceProvidersSlice/serviceProvidersSlice';
import userDetailsSlice from './features/usersSlice/userDetails';
import rolesSlice from './features/usersSlice/rolesSlice';
import forceCancelAuctionSlice from './features/auctionSlice/forceCancelAuctionSlice';
import inspectionSlice from './features/inspectionSlice/inspectionSlice';
import actvateUserSlice from './features/approvalsSlice/actvateUserSlice';
import unlockUsersSlice from './features/approvalsSlice/unlockUsersSlice';
import auctioneerRequestsSlice from './features/approvalsSlice/auctioneerRequestsSlice';
import carInspectionSlice from './features/approvalsSlice/carInspectionSlice';
import repushAuctionSlice from './features/auctionSlice/repushAuctionSlice';
import extendAuctionSlice from './features/auctionSlice/extendAuctionSlice';
import carUpdateImages from './features/itemsSlice/imagesSlice';
import selectAuctionWinnerSlice from './features/auctionSlice/selectWinnerSlice';
import verticalsSlice from './features/verticalsSlice/verticalsSlice';
import createVerticalsSlice from './features/verticalsSlice/createVerticalsSlice';
import verticalDetailsSlice from './features/verticalsSlice/verticalDetailsSlice';
import createBaseFormFieldsSlice from './features/verticalsSlice/createOrUpdateFormFieldsSlice';
import rmSlice from './features/usersSlice/rmSlice';
import freePackageSlice from './features/usersSlice/freePackageSlice';
import conciergeSlice from './features/itemsSlice/conciergeSlice';
import leadsSlice from './features/usersSlice/leadsSlice';
import historySlice from './features/usersSlice/historySlice';
import reviewSlice from './features/usersSlice/reviewSlice';
import notificationSlice from './features/usersSlice/notificationSlice';
import freeAuctionSlice from './features/usersSlice/freeAuctionSlice';
import paymentSlice from './features/usersSlice/paymentSlice';
import autocompleteOptionsSlice from './features/verticalsSlice/autocompleteOptionsSlice';
import autocompleteOptionsSecondarySlice from './features/verticalsSlice/autocompleteOptionsSecondarySlice';
import shipmentsSlice from './features/usersSlice/shipmentsSlice';
import flagSlice from './features/flagsSlice/flagSlice';

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, authSlice);

export const store = configureStore({
  reducer: {
    auth: persistedReducer,
    users: usersSlice,
    userDetails: userDetailsSlice,
    auctions: auctionSlice,
    auctionDetails: auctionDetailsSlice,
    items: itemsSlice,
    liveAuctions: liveAuctionsSlice,
    topBiddedAuctions: topBiddedAuctionsSlice,
    dashboard: dashboardSlice,
    carDetails: carDetailsSlice,
    services: servicesSlice,
    serviceProviders: serviceProvidersSlice,
    vouchers: vouchersSlice,
    forceCancel: forceCancelAuctionSlice,
    inspection: inspectionSlice,
    activateUsers: actvateUserSlice,
    unlockUsers: unlockUsersSlice,
    auctioneerRequests: auctioneerRequestsSlice,
    carInspections: carInspectionSlice,
    repostAuction: repushAuctionSlice,
    forceCancelAuction: forceCancelAuctionSlice,
    extendAuction: extendAuctionSlice,
    updateImages: carUpdateImages,
    selectWinner: selectAuctionWinnerSlice,
    verticals: verticalsSlice,
    createVertical: createVerticalsSlice,
    verticalDetails: verticalDetailsSlice,
    formFields: createBaseFormFieldsSlice,
    roles: rolesSlice,
    rm: rmSlice,
    freePackage: freePackageSlice,
    concierge: conciergeSlice,
    leads: leadsSlice,
    autocompleteOptions: autocompleteOptionsSlice,
    autocompleteOptionsSecondary: autocompleteOptionsSecondarySlice,
    review: reviewSlice,
    history: historySlice,
    notification: notificationSlice,
    freeAuctions: freeAuctionSlice,
    payments: paymentSlice,
    shipments: shipmentsSlice,
    flags: flagSlice
  },
  middleware: [thunk]
});
export const persistor = persistStore(store);
export default { store, persistor };
